<template>
  <div
    class="answer_game"
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(183,208,208,0.8)"
  >
    <div class="answer_ques_title">
      <p>当前可用游戏</p>
    </div>
    <el-table :data="tableDaba" style="width: 100%" :show-header="false" :cell-style="timeStyle">
      <el-table-column type="index" align="center"></el-table-column>
      <el-table-column prop="title" align="left">
        <template slot-scope="scope">
          <span @click="staGame(scope.row)" style="display:flex;">{{ scope.row.title}}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { getUserGame } from "@/api/answer.js";
export default {
  data() {
    return {
      loading: true,
      quesList: [],
      tableDaba: [],
      type: 0,
    };
  },
  created() {
    this.loadquesList();
  },
  methods: {
    // 数据加载
    loadquesList() {
      getUserGame({ type: this.type }).then((res) => {
        this.tableDaba = res.data;
        this.loading = false;
      });
    },
    // 表格样式
    timeStyle(row) {
      return "background:rgb(226, 239, 240);color:#262626;border-bottom: 1px solid #707070";
    },
    // 跳转问卷填写
    staGame(row) {
      // console.log(row);
      // // window.open(row.local_url);
      // var winName = "newWin";
      // var awidth = 550;
      // var aheight = 850;
      // if (row.sign == "idiom") {
      //   awidth = 1350;
      //   aheight = 650;
      // }
      // var atop = (screen.availHeight - aheight) / 2;
      // var aleft = (screen.availWidth - awidth) / 2;
      // var param0 = "scrollbars=0,status=0,menubar=0,resizable=2,location=0";
      // var params =
      //   "top=" +
      //   atop +
      //   ",left=" +
      //   aleft +
      //   ",width=" +
      //   awidth +
      //   ",height=" +
      //   aheight +
      //   "," +
      //   param0;
      // let win = window.open(row.local_url, winName, params);
      // win.focus();
      // this.$router.push(row.local_url);
      window.location.href = row.local_url;
    },
    // 错误触发
    err() {
      this.$message({
        showClose: true,
        duration: 1000,
        message: "该问卷已被使用",
        type: "warning",
      });
    },
  },
};
</script>
<style lang="less">
.answer_game {
  width: 90%;
  margin: 10px auto;
  border-radius: 15px;
  box-shadow: rgb(124, 124, 124) 4px 2px 15px;
  .answer_ques_title {
    border-radius: 15px 15px 0px 0px;
    background: rgb(220, 239, 241);
    p {
      text-align: center;
      padding: 10px 0;
      font-size: 20px;
      font-weight: 700;
      background: linear-gradient(to right, rgb(0, 136, 125), rgb(5, 201, 184));
      -webkit-background-clip: text;
      color: transparent;
      text-shadow: white 1px 3px 9px;
    }
  }
  .el-table {
    border-radius: 0px 0px 15px 15px;
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td {
    color: black !important;
    background-color: rgb(139, 179, 177) !important;
    font-weight: 700;
    transition: all 0.25s;
  }

  // 加载等待框
  .el-loading-mask {
    .el-loading-spinner {
      .el-icon-loading {
        color: rgb(34, 77, 85);
      }
      .el-loading-text {
        color: rgb(34, 77, 85);
        font-weight: 700;
      }
    }
  }
  .isanswer {
    text-decoration: line-through;
    color: #aaaaaa;
  }
}
</style>